/* eslint-disable */
import React, {useState} from 'react';
import ImageLoad from '../Wallet/ImageLoad';

let crytponuns_rarity_ranking = require('database/crytponuns_rarity_ranking.json');
let cryptonuns_rarity_scores = require('database/cryptonuns_rarity_scores.json');
let xoxno_rarity_ranking = require('database/xoxno_rarity_ranking.json');
let xoxno_rarity_scores = require('database/xoxno_rarity_scores.json');

let nuns = []
for(var i in crytponuns_rarity_ranking){

    let item = { "ID": i, "ranking":crytponuns_rarity_ranking[i] + 1 }
    for( var j in cryptonuns_rarity_scores){
        if( j == i ){
            item.score = parseFloat( cryptonuns_rarity_scores[j].toFixed(2) )
        }
    }
    for( var k in xoxno_rarity_ranking){
        if( k == i ){
            item.xoxno_ranking = xoxno_rarity_ranking[k] + 1
        }
    }
    for( var l in xoxno_rarity_scores){
        if( l == i ){
            item.xoxno_score = parseFloat( xoxno_rarity_scores[l].toFixed(2) )
        }
    }
    //item.url = './nfts/'+i+'_comprimat.png'
    //item.url = 'http://3.64.45.20/images/'+i+'_comprimat.png'
    
    /*
    1-2500: QmYXmgyjTJUsU4XHq6oL5j6DL9yYmbmvoCZJuYuGovzEKE
    2501 - 5000: QmUGQntGxmYkoCPRUaCituWpPdamxSdUnVSeY5rigQrc8q
    5001 - 7500: QmbNcssaNyUydyYEipmewNhc4Rwhh4K1freXfiQrZWcxqk
    7501 - 10000: QmaWGRypCkqSDnpNGVPwnoucFfvJbNdUHenxJ2ArMt9Aqu
    */
    
    
    item.url = 'https://cdn.cryptonuns.io/images/'+i+'_comprimat.png'; //base URL for full image
    if( i <= 2500 ){
        item.url = 'https://ipfs.io/ipfs/QmYXmgyjTJUsU4XHq6oL5j6DL9yYmbmvoCZJuYuGovzEKE/'+i+'.png'; //base URL for full image
    }else
    if( i > 2500 && i <= 5000 ){
        item.url = 'https://ipfs.io/ipfs/QmUGQntGxmYkoCPRUaCituWpPdamxSdUnVSeY5rigQrc8q/'+i+'.png'; //base URL for full image
    }else
    if( i > 5000 && i <= 7500 ){
        item.url = 'https://ipfs.io/ipfs/QmbNcssaNyUydyYEipmewNhc4Rwhh4K1freXfiQrZWcxqk/'+i+'.png'; //base URL for full image
    }else
    if( i > 7500 && i <= 10000 ){
        item.url = 'https://ipfs.io/ipfs/QmaWGRypCkqSDnpNGVPwnoucFfvJbNdUHenxJ2ArMt9Aqu/'+i+'.png'; //base URL for full image
    }
    
    item.thumb_url = 'https://cdn.cryptonuns.io/images/'+i+'_comprimat.png'
    item.name = "Nun #"+i

    nuns.push( item )
}

const Collection = () => {
    
    const [loadedNfts, setLoadedNfts] = useState([]);
    const [listSize] = useState(19);

    const [searchBy, setSearchBy] = useState("");
    const [sortBy, setSortBy] = useState("ID-asc");

    const [limit, setLimit] = useState(20);
    
    const showMoreItems = () => {

        //console.log( walletNfts )
        //console.log( loadedNfts )
        
        let size = loadedNfts.length
        let array = loadedNfts
        
        let all = nuns
        if( searchBy != '' ){
            let filtered = JSON.parse( JSON.stringify( nuns ) )
            all = filtered.filter((item)=>{ return item.ID.includes( searchBy ) })
        }
        all.forEach((item, i) => {
            if (i >= size && i <= size + listSize) {
                array.push(item)
            }
         })

        setLoadedNfts(array);

        let newLimit = limit + limit
        setLimit(newLimit)

    }

     const handleChangeSortBy = e => {
        
        let sort = e.target.value;
        //console.log(sort)
        setSortBy(sort)
         
        let sort_options = sort.split("-");
        let sort_option = sort_options[0]
        let sort_direction = sort_options[1]
        
        if( sort_direction == 'asc' ){
            nuns = nuns.sort((a,b) => a[sort_option] - b[sort_option]); // b - a for reverse sort
        }else{
            nuns = nuns.sort((a,b) => b[sort_option] - a[sort_option]); // b - a for reverse sort
        }
            
        //console.log(nuns)
        
        loadNFTs( nuns )
         
    }
     
     const handleChangeSearch = e => {
        
        let search = e.target.value;
        //console.log(sort)
        setSearchBy(search)
        
        if( search != "" ){
            
            let filtered = JSON.parse( JSON.stringify( nuns ) )
            filtered = filtered.filter((item)=>{ return item.ID.includes( search ) })

            loadNFTs( filtered )
            
        }else{
            loadNFTs( nuns)
        }
        /*
        let loaded = []
        nuns.forEach((item, i) => {
            if (i <= listSize) {
                loaded.push(item)
            }
        })
        setLoadedNfts(loaded);   
        */
        //console.log(array)
    }
     
    const loadNFTs = (array = nuns) => {
        
        let loaded = []
        setLoadedNfts(loaded);
        
        array.forEach((item, i) => {
            if (i <= listSize) {
                loaded.push(item)
            }
        })
        setLoadedNfts(loaded); 
        
    }
    
    const sortItems = () => {
        
        console.log( sortBy )
        nuns = nuns.sort((a,b) => a.sortBy - b.sortBy); // b - a for reverse sort
        loadNFTs()
    }
    
    React.useEffect(() => {

        loadNFTs( nuns)


    }, []);

  return (
    <>
        <div className="">
            <div className="container">
                <div className="row ">

                    <div className="col-12 text-center">
                        <h1 className="font-weight-bold"> Rarity </h1>
                    </div>

                    <div className="col-12 col-md-6 offset-md-2 text-center mt-3">
                        <input type="text" className="form-control search-bar input-bordered-white" placeholder="Search NFTs By ID ..." value={searchBy} onChange={e => handleChangeSearch(e)} />
                    </div>

                    <div className="col-12 col-md-2 mt-3 text-center">
                        <span className="mr-4" > Sort by: </span>

                        <select className="form-control d-inline w-50 input-bordered-white" value={sortBy} onChange={e => handleChangeSortBy(e)} >
                            <option value="ID-asc" > ID ASC </option>
                            <option value="ID-desc" > ID DESC </option>
                            <option value="ranking-asc" > Ranking ASC </option>
                            <option value="ranking-desc" > Ranking DESC </option>
                            <option value="score-asc" > Score ASC </option>
                            <option value="score-desc" > Score DESC </option>
                            <option value="xoxno_ranking-asc" > XOXNO Ranking ASC </option>
                            <option value="xoxno_ranking-desc" > XOXNO Ranking DESC </option>
                            <option value="xoxno_score-asc" > XOXNO Score ASC </option>
                            <option value="xoxno_score-desc" > XOXNO Score DESC </option>

                        </select>
                    </div>

                </div> 
            </div>
        </div>
        <div className='container'>

            <div className="row mt-5 text-center" >
                    
                {
                    loadedNfts.map((nft, i) => (
                        <div key={i} className='col-sm-12 col-lg-3 ' >
                            <div className="w-100 horizontal-line mb-4"></div>  
                            <div  className='col-10 offset-1 p-0 position-relative rarity-nft-item' >
                                <a href={nft.url}  className="image-full-link" title="See full size image" target="_blank" > <i className="fa fa-image"></i> </a>
                                <ImageLoad uri={nft.thumb_url} />                      
                                <p className="heading4" >{nft.name}</p>
                                <div className="row mb-0" >
                                    <div className="col-6 " > Ranking </div>
                                    <div className="col-6 text-right" > Score </div>
                                </div>
                                <div className="row mb-0" >
                                    <div className="col-6 color-primary heading5" > {'#'+nft.ranking} </div>
                                    <div className="col-6 color-primary heading5 text-right" > {nft.score} </div>
                                </div>
                                <div className="row mb-0 mt-2" >
                                    <div className="col-6 " > XOXNO Ranking </div>
                                    <div className="col-6 text-right" > XOXNO Score </div>
                                </div>
                                <div className="row mb-4" >
                                    <div className="col-6 color-primary heading5" > {'#'+nft.xoxno_ranking} </div>
                                    <div className="col-6 color-primary heading5 text-right" > {nft.xoxno_score} </div>
                                </div>
                            </div>
                            <div className="w-100 horizontal-line mb-4"></div>  
                        </div>
                    ))
                }

                { nuns.length > 0 && nuns?.length > loadedNfts?.length &&
                    <div className="col-12 text-center cursor-pointer mt-5"   >
                        <a className="mybtn btn-bordered-white pt-2 pb-2" onClick={showMoreItems} >
                            Load more
                        </a>
                    </div>
                }
      
            </div>

        </div>
    </>
  );
};

export default Collection;
/*
<div className="col-12 col-md-4 offset-md-3 d-none" >
                        <PieChart data={pieData} label={({ dataEntry }) => dataEntry.value + "%" } startAngle={90} labelStyle={{ "fontSize": '5px' }} />  
                    </div>

                    <div className="col-12 col-md-4 text-left pt-5 pl-5 d-none" >
                        {tokenomics && tokenomics?.length > 0 && tokenomics.map((item, i) => {
                            return (
                                <div className="row mt-2 mb-1" key={i} >
                                    <div className="col-12" > 
                                        <span className="small-box" style={{background: item.color }}  ></span>
                                        {item.name} { item.suplyPercentage }% 
                                    </div>
                                </div>
                            );
                        })}
                    </div>
*/