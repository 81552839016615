/* eslint-disable */
import React, {useState} from 'react';

const Collection = () => {
    
  React.useEffect(() => {
    
        
            
  }, []);

  return (
    <>
        <div className="">
            <div className="container">
                <div className="row ">

                    <div className="col-12 text-center">
                        <h1 className="font-weight-bold"> Crypto Nuns Story </h1>
                    </div>

                </div> 
            </div>
      
            <div className="row mt-4 mb-4 ">

                <img src="./Twitter-cover-design-2.jpg" className="w-100 m-0 p-0" />

            </div> 
      
        </div>
        <div className='container'>

            <div className="row mt-5 text-center" >
                    
                <div className="col-12" > 
                    
                    {/* CHAPTER 1 */}
                    <div className="w-100 horizontal-line mb-4"></div>   
                    
                    <div className="col-12 col-md-8 offset-md-2" >
                        <div className="row " >

                            <div className="col-12 col-md-4" >
                                <img src="./cryptonuns_logo.png" className="m-auto w-100 col-8 offset-2 col-md-12 offset-md-0" />
                            </div> 
                            <div className="col-12 col-md-8 text-left pt-3" >

                                <h4 className="heading4 mt-3 mt-md-5 text-left" > Prologue </h4> <br/>
      
                                <a href="https://cryptonunsnft.gitbook.io/cryptonunsnft/prologue" target="_blank" rel="noreferrer" className="mybtn btn-bordered-white pt-2 pb-2" > Read </a>

                            </div> 


                        </div>
                    </div>
                    
                    {/* CHAPTER 2 */}
                    <div className="w-100 horizontal-line mt-4 mb-4"></div>   
                    
                    <div className="col-12 col-md-8 offset-md-2" >
                        <div className="row " >

                            <div className="col-12 col-md-4" >
                                <img src="./Nakano.png" className="m-auto w-100 col-8 offset-2 col-md-12 offset-md-0" />
                            </div> 
                            <div className="col-12 col-md-8 text-left pt-3" >

                                <h4 className="heading4 mt-3 mt-md-5 text-left" > Nakano </h4> <br/>
      
                                <a href="https://cryptonunsnft.gitbook.io/cryptonunsnft/chapter-one-truth-in-tokyo" target="_blank" rel="noreferrer" className="mybtn btn-bordered-white pt-2 pb-2" > Read </a>

                            </div> 


                        </div>
                    </div>
                    
                    {/* CHAPTER 3 */}
                    <div className="w-100 horizontal-line mt-4 mb-4"></div>   
                    
                    <div className="col-12 col-md-8 offset-md-2" >
                        <div className="row " >

                            <div className="col-12 col-md-4" >
                                <img src="./Ashanti.png" className="m-auto w-100 col-8 offset-2 col-md-12 offset-md-0" />
                            </div> 
                            <div className="col-12 col-md-8 text-left pt-3" >

                                <h4 className="heading4 mt-3 mt-md-5 text-left" > Ashanti </h4> <br/>
      
                                <a href="https://cryptonunsnft.gitbook.io/cryptonunsnft/chapter-three-continental-drift" target="_blank" rel="noreferrer" className="mybtn btn-bordered-white pt-2 pb-2" > Read </a>

                            </div> 


                        </div>
                    </div>
                    
                    {/* CHAPTER 4 */}
                    <div className="w-100 horizontal-line mt-4 mb-4"></div>   
                    
                    <div className="col-12 col-md-8 offset-md-2" >
                        <div className="row " >

                            <div className="col-12 col-md-4" >
                                <img src="./Valda.png" className="m-auto w-100 col-8 offset-2 col-md-12 offset-md-0" />
                            </div> 
                            <div className="col-12 col-md-8 text-left pt-3" >

                                <h4 className="heading4 mt-3 mt-md-5 text-left" > Valda </h4> <br/>
      
                                <a href="https://cryptonunsnft.gitbook.io/cryptonunsnft/chapter-five-a-night-walker" target="_blank" rel="noreferrer" className="mybtn btn-bordered-white pt-2 pb-2" > Read </a>

                            </div> 


                        </div>
                    </div>
                    
                    {/* CHAPTER 5 */}
                    <div className="w-100 horizontal-line mt-4 mb-4"></div>   
                    
                    <div className="col-12 col-md-8 offset-md-2" >
                        <div className="row " >

                            <div className="col-12 col-md-4" >
                                <img src="./jamari.png" className="m-auto w-100 col-8 offset-2 col-md-12 offset-md-0" />
                            </div> 
                            <div className="col-12 col-md-8 text-left pt-3" >

                                <h4 className="heading4 mt-3 mt-md-5 text-left" > Jamari </h4> <br/>
      
                                <a href="https://cryptonunsnft.gitbook.io/cryptonunsnft/chapter-seven-claws-of-nepal" target="_blank" rel="noreferrer" className="mybtn btn-bordered-white pt-2 pb-2" > Read </a>

                            </div> 


                        </div>
                    </div>
                    
                    {/* CHAPTER 6 */}
                    <div className="w-100 horizontal-line mt-4 mb-4"></div>   
                    
                    <div className="col-12 col-md-8 offset-md-2" >
                        <div className="row " >

                            <div className="col-12 col-md-4" >
                                <img src="./cryptonuns_logo.png" className="m-auto w-100 col-8 offset-2 col-md-12 offset-md-0" />
                            </div> 
                            <div className="col-12 col-md-8 text-left pt-3" >

                                <h4 className="heading4 mt-3 mt-md-5 text-left" > Epilogue </h4> <br/>
      
                                <a href="https://cryptonunsnft.gitbook.io/cryptonunsnft/chapter-nine-all-lines-meet" target="_blank" rel="noreferrer" className="mybtn btn-bordered-white pt-2 pb-2" > Read </a>

                            </div> 


                        </div>
                    </div>

                    <div className="w-100 horizontal-line mt-4"></div>   

                </div>
      
            </div>

        </div>
    </>
  );
};

export default Collection;
/*
<div className="col-12 col-md-4 offset-md-3 d-none" >
                        <PieChart data={pieData} label={({ dataEntry }) => dataEntry.value + "%" } startAngle={90} labelStyle={{ "fontSize": '5px' }} />  
                    </div>

                    <div className="col-12 col-md-4 text-left pt-5 pl-5 d-none" >
                        {tokenomics && tokenomics?.length > 0 && tokenomics.map((item, i) => {
                            return (
                                <div className="row mt-2 mb-1" key={i} >
                                    <div className="col-12" > 
                                        <span className="small-box" style={{background: item.color }}  ></span>
                                        {item.name} { item.suplyPercentage }% 
                                    </div>
                                </div>
                            );
                        })}
                    </div>
*/