/* eslint-disable */
import React, {useState} from 'react';

const Collection = () => {
    
  React.useEffect(() => {
    
        
            
  }, []);

  return (
    <>
        <div className="">
            <div className="container">
                <div className="row ">

                    <div className="col-12 text-center">
                        <h1 className="font-weight-bold heading2"> About Nuns </h1>
                    </div>

                </div> 
            </div>
        </div>
        <div className='container'>

            <div className="row mt-5 text-justify " >
                    
                <div className="col-12 col-md-10 offset-md-1 " >
                    
                    <div className="color-white text" >
      
                        CryptoNuns is an NFT project built on three pillars that stand also for the main objectives. 
                        <br/><br/>

                        Our work aims to empower: <br/>
                        I. Digital art and artists<br/>
                        II. Women<br/>
                        III. Elrond NFT business
                        <br/><br/>

                        We want to use art as a medium of celebrating and transmitting values as kindness, dedication, equal access to opportunity, equal treatment for all human beings, fighting for a better world and communities. 
                        All these being also the foundation of Elrond Network and community
                        <br/><br/>

                        The development of the project also aims, among others, to further bridge the physical art world with digital world by providing blockchain powered gateways and tools for collectors. 
                        <br/>
                        More details related to NFT business potential will be  disclosed in our roadmap and Discord.
                        <br/><br/>

                        Now, let's dive in and experience togheter a little "breakdown" of every pillar we mentioned above. <br/>
                        While we live in a rushed world, where everything has an incredible rapid pace, we kindly ask you to slow down a bit and read our "confession". <br/>
                        We'll try to keep it short pray <br/>
                        <br/><br/>

                        <h4 className="heading5" >I. EMPOWERING DIGITAL ART AND ARTISTS</h4> <br/>

                        Through our explicit positioning as an art-centered NFT project, we seek to strengthen the belief that NFT future is not all about flipping. 
                        NFTs are not just another asset to buy low and sell high, based on temporary hype.
                        <br/><br/>

                        We thrive for proving that NFT art can be simultaneously "simply art"and "a generating profit tool", if one can blend: <br/>
                        *technical and aesthetical accomplishments <br/>
                        *using art as e medium of conveying messages we consider important for our society <br/>
                        *focusing on business dimension <br/>
                        <br/>

                        Regarding artists, we know the struggle that consumes their existence in times and places where ROI is king. <br/>
                        We believe that one way to adapt is to morph their true call and skills to meet the new digital world's expectations. <br/>
                        Artists must meet the "customers" in the middle
                         <br/> <br/>
      
                        <h4 className="heading5" >II. EMPOWERING WOMEN </h4> <br/>
                        
                             We had hard times deciding if we go this way. While some may see it as a political message, we assure you it's not the case. Gross politics is a "no-go zone" in art. But reality matters! And the "fight" is far from over. 
                        And we decided to assume it!
                        <br/><br/>
                             Our characters weren't chosen by chance. Neither their stories that you'll discover soon.  <br/>
                        We trust that all pieces will fall into place in the end. <br/>
                        This is just one of the pillars and we'll adress it like that, but it's an open statement that we make.
                        <br/><br/>
      
                        <h4 className="heading5" >III. NFT business </h4> <br/>

                             We decided to approach this dimension in what we consider a hybrid strategy. <br/>
                        Our concrete efforts and discussions are already going in two directions: <br/>
                        *bridge the physical art world with digital world  <br/>
                        *becoming a tool provider for metaverse business.
                        <br/><br/>
                             This doesn't mean we'll not use more traditional tools. Only wanted to underline our main perspective. <br/>
                        NFT business it's still in infancy when it comes to models practices.  <br/>
                        But that's exactly what we like the most.  <br/>
                        We assume ourselves to the last pillar!
                        <br/><br/>
                             This is part of our story. This is somehow...us. This is CryptoNuns!
                        <br/><br/>
                        We have much more to tell you.  <br/>
                        We want you to know us. To understand us and our vision, our project.  <br/>
                        We might be dreamers, but we're grounded dreamers!
      
                    </div>
      
                </div>
      
            </div>

        </div>
    </>
  );
};

export default Collection;
/*
<div className="col-12 col-md-4 offset-md-3 d-none" >
                        <PieChart data={pieData} label={({ dataEntry }) => dataEntry.value + "%" } startAngle={90} labelStyle={{ "fontSize": '5px' }} />  
                    </div>

                    <div className="col-12 col-md-4 text-left pt-5 pl-5 d-none" >
                        {tokenomics && tokenomics?.length > 0 && tokenomics.map((item, i) => {
                            return (
                                <div className="row mt-2 mb-1" key={i} >
                                    <div className="col-12" > 
                                        <span className="small-box" style={{background: item.color }}  ></span>
                                        {item.name} { item.suplyPercentage }% 
                                    </div>
                                </div>
                            );
                        })}
                    </div>
*/