/* eslint-disable */
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const { address } = useGetAccountInfo();

  const handleLogout = () => {
    logout(`${window.location.origin}/`);
    document.getElementById('toggleHamburger').click();
  };

  const toggleMenu = () => {
     document.getElementById('toggleHamburger').click();
  };

  const isLoggedIn = Boolean(address);


  return (
  
    <header className="header">
        <div className="container">
            <div className="row">

                <div className="logo-wrapper col-auto col-auto">
                        <a className="header_logo" href="/"> 
                            <img id="logo" src="./cryptonuns_logo.png" />
                        </a>	
                </div>

                    <input id="toggle" type="checkbox" />
                    <label id="toggleHamburger" className="toggle-container" htmlFor="toggle">
                        <span className="button button-toggle">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </span>
                    </label>


                <nav className="navbar nav-wrapper col">
                    <div className="container-fluid justify-content-sm-start justify-content-md-end">
                        <ul id="nav" className="nab navbar-nav navbar-right text-400">
                        
                            <li  className={window.location.pathname == '/' ? 'menu-item current' : 'menu-item '} >  <Link to="/" onClick={()=>{ toggleMenu(); }} > Home </Link> </li>
                            
                            <li className="menu-item ml-3 dropdown">

                                <a href="#" className="menu-item" > About <i className="fa fa-chevron-down ml-md-4" /> </a>
                                
                                <ul className="dropdown-menu border-radius">
                                
                                    <li  className={window.location.pathname == '/about-nuns' ? 'menu-item current' : 'menu-item '} >  <Link to="/about-nuns" onClick={()=>{ toggleMenu(); }} > About Nuns </Link> </li>

                                     <li  className={window.location.pathname == '/story' ? 'menu-item current' : 'menu-item '} >  <Link to="/story" onClick={()=>{ toggleMenu(); }} > Story </Link> </li>

                                    <li  className={window.location.pathname == '/business-vision' ? 'menu-item current' : 'menu-item '} >  <Link to="/business-vision" onClick={()=>{ toggleMenu(); }} > Business Vision / Roadmap </Link> </li>

                                    <li  className={window.location.pathname == '/comic-book' ? 'menu-item current' : 'menu-item '} >  <Link to="/comic-book" onClick={()=>{ toggleMenu(); }} > Comic Book </Link> </li>
                                    
                                </ul>

                            </li>
                            
                            {/*
                            <li  className={window.location.pathname == '/about-nuns' ? 'menu-item current' : 'menu-item '} >  <Link to="/about-nuns" onClick={()=>{ toggleMenu(); }} > About Nuns </Link> </li>
                            */}
                            
                            { /*
                            <li  className={window.location.pathname == '/collection' ? 'menu-item current' : 'menu-item '} >  <Link to="/collection" onClick={()=>{ toggleMenu(); }} > Collection </Link> </li>
                            */
                            }
                           
                            <li  className={window.location.pathname == '/staking' ? 'menu-item current' : 'menu-item '} >  <Link to="/staking" onClick={()=>{ toggleMenu(); }} > Staking </Link> </li>
                            
                            <li  className={window.location.pathname == '/rarity' ? 'menu-item current' : 'menu-item '} >  <Link to="/rarity" onClick={()=>{ toggleMenu(); }} > Rarity </Link> </li>
                            
                            <li  className={window.location.pathname == '/mint' ? 'menu-item current' : 'menu-item '} >  <Link to="/mint" onClick={()=>{ toggleMenu(); }} > Mint </Link> </li>
                            
                            { /*isLoggedIn && <li  className={window.location.pathname == '/dashboard' ? 'menu-item current' : 'menu-item '} >  <Link to="/dashboard" onClick={()=>{ toggleMenu(); }} > Dashboard </Link> </li> */ }
                            
                            <li className="menu-item ml-3 dropdown">
                                { !isLoggedIn && <Link to="/connect" className="mybtn btn-bordered-white pt-2 pb-2" onClick={()=>{ toggleMenu(); }}> Connect </Link> }
                                { isLoggedIn && 

                                    <>
                                        <Link className="mybtn ml-lg-auto btn-bordered-white" onClick={()=>{ toggleMenu(); }} to="/wallet"> Account <i className="fa fa-chevron-down ml-md-4" /> </Link>
                                        <ul className="dropdown-menu border-radius">
                                            <li className="nav-item mt-2 mb-2">
                                                <Link to="/wallet" className="nav-link d-block text-center" onClick={()=>{ toggleMenu(); }} > My wallet </Link>
                                            </li>

                                            <li className="nav-item mt-2 mb-2 d-block text-center"> 
                                                <a onClick={()=>{ handleLogout(); }} className="mybtn btn-bordered-white pt-2 pb-2" > Disconnect </a> 
                                            </li>
                                        </ul>
                                    </>
                                    
                                }
                                
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="dimmer"></div>

            </div>
        </div>
    </header>
    
  );
};

export default Navbar;

/*

<BsNavbar className='bg-white border-bottom px-4 py-3'>
      <div className='container-fluid'>
        <Link
          className='d-flex align-items-center navbar-brand mr-0'
          to={isLoggedIn ? routeNames.dashboard : routeNames.home}
        >
          <ElrondLogo className='elrond-logo' />
          <span className='dapp-name text-muted'>{dAppName}</span>
        </Link>

        <Nav className='ml-auto'>
          {isLoggedIn && (
            <NavItem>
              <a href={routeNames.home} onClick={handleLogout}>
                Close
              </a>
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
*/
