/* eslint-disable */
import * as React from 'react';


const Home = () => {

    // Sticky Menu Area
     React.useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, []);


    /* Method that will fix header after a specific scrollable */
   const isSticky = (e) => {
        //const header = document.querySelector('.sticky-div');
        //const scrollTop = window.scrollY;
        //scrollTop >= 1550  ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

  return (
  <>
    <div className="page-content mt-md-0 full-width bg-black">
        <section id="canvas" className="pt-md-0" >
            
            {/*
            <video className="main-video" autoPlay playsInline muted  >
                    <source src="./main_video.mp4" type="video/mp4"/>
                    <p>Your user agent does not support the HTML5 Video element.</p>
            </video>
            */}
            
            <img src="./nuns_home.png" className="main-video" />
        
            <div className="position-md-absolute top-0 w-100" >
                <div className="container w-100 main-header">
                    <div className="row ">

                        <div className="col-12 text-center mt-1 mt-md-5">
                            <div className="col-12 section-title text-center pt-3">

                                <h1 className="text-center text-bold text-700 mt-5 heading0 font-primary"> CRYPTO NUNS </h1>
                                <h2 className="text-center mt-4 heading3 font-secondary"> 3D semi-realistic Collection of 10.000 unique Nuns on the Elrond Network Blockchain </h2>

                            </div>

                        </div>

                    </div> 
                </div>
            </div>
            
        </section>
    </div>

    <section className="">
		<div className="container p-0">
			<div className="row ">
                
                <div className="col-12 text-justify mt-md-0">
				    
                    <div className="col-12 section-title ">
                                                
                        <h1 className="text-center text-700 mt-3 heading1 font-primary"> ABOUT THE NUNS </h1>
                        <p className="mt-5 color-white text">
                            "The world has changed, the times are different, and the nuns felt the need for them again, the need to change the old financial system, but for that a change was needed ... These nuns are wild, relentless, with different talents and very skilled.
                            <br/><br/>
                            The transformation was necessary to adapt to a crypto world of demanding quality: from an innocent nun to a dominant one, from a calm nun to a provocative one.
                            <br/><br/>
                            CryptoNuns are here to stay and shape the NFT world!"
                        </p>
        
                    </div>
                    
				</div>
                	
 			</div> 
		</div>
	</section>

    {/*
    <section className="d-none">
		<div className="container p-0">
			<div className="row ">
                
                <div className="col-12 text-justify mt-md-0">
                    <div className="col-12 section-title ">                                                
                        <h1 className="text-center text-700 mt-3 heading1 font-primary"> ROADMAP </h1>
                    </div>
				</div>
                    
                <div className="row mt-3" >
                    <div className="col-12 col-md-6 p-md-5 position-relative" >
                        <video className="w-100 sticky-div" autoPlay playsInline muted  >
                                <source src="./telegram-cloud-document-4-5969783419343735772_resized.mp4" type="video/mp4"/>
                                <p>Your user agent does not support the HTML5 Video element.</p>
                        </video>
                    </div>
                    <div className="col-12 col-md-6 ml-4 mt-3 mt-md-0 ml-md-0 border-left-gray-light" >
                        
                        <div className="col-12 mt-4 mb-4" >
                            <h1 className="text-left text-700 mt-3 heading2 font-primary"> <span className="timeline-bullet" ></span>  Step 1 - Project Launch Stage </h1>
                            <p className="mt-3 color-white text-small">
                                 - Unveiling CryptoNuns artwork <br/>
                                 - Website launch <br/>
                                 - Social Media Campaigns (Telegram, Discord and Twitter) to increase project reach and community <br/>
                                 - Airdrops for selected IndieNFTArtwork holders (based on snapshots taken)
                            </p>
                        </div>
                        <div className="col-12 mt-5 mb-5" >
                            <h1 className="text-left text-700 mt-3 heading2 font-primary"> <span className="timeline-bullet" ></span> Step 2 - Project Sale Stage </h1>
                            <p className="mt-3 color-white text-small">
                                - No presale or whitelisting <br/>
                                - Fair sale of our 10.000 CryptoNuns with a generous limit per wallet (date and limit TBA) Prices will be as follows: <br/>
                                - 0,49 $eGLD for IndieNftArtwork holders* <br/>
                                - 0,59 $eGLD for IndieNftArtwork collaboration holders* <br/>
                                - 0,69 $eGLD for everyone else 
                                
                                <br/> <br/>
                                *Discounts granted to all IndieNFTArtwork holders and also to some other project holders( to be announced) based on snapshots taken

                            </p>
                        </div>
                        <div className="col-12 mt-5 mb-5" >
                            <h1 className="text-left text-700 mt-3 heading2 font-primary"> <span className="timeline-bullet" ></span> Step 3 - Community Rewards Stage </h1>
                            <p className="mt-3 color-white text-small">
                                
                                 - CryptoNuns holders will earn staking rewards / passive income from holding their CryptoNuns NFTs <br/>
                                 - Giveaways for holders

                            </p>
                        </div>
                        <div className="col-12 mt-5 mb-5" >
                            <h1 className="text-left text-700 mt-3 heading2 font-primary"> <span className="timeline-bullet" ></span> Step 4 - The CryptoNuns Comic Book </h1>
                            <p className="mt-3 color-white text-small">
                                
                                - Owners of a CryptoNuns NFT will have exclusive access to the CryptoNuns story on our website <br/>
                                - Based on this story we will release "The CryptoNuns Comic Book" (limited cover NFTs + comic book - 250 editions per act) <br/>
                                - Only the CryptoNuns holders will be allowed to participate in the raffle for this sale (conditions TBA) <br/>

                            </p>
                        </div>
                        <div className="col-12 mt-5 mb-5" >
                            <h1 className="text-left text-700 mt-3 heading2 font-primary"> <span className="timeline-bullet" ></span> Step 5 - Eternal CryptoNuns </h1>
                            <p className="mt-3 color-white text-small">
                                
                                - We will explore gamification as well as VR+AR use of the 3D CryptoNuns models

                            </p>
                        </div>
                        
                    </div>
                </div>
                	
 			</div> 
		</div>
	</section>
    */}
    
    {/*
    <section className="">
		<div className="container p-0">
			<div className="row ">
                
                <div className="col-12 text-justify mt-md-0">
				    
                    <div className="col-12 section-title ">
                        <h1 className="text-center text-700 mt-3 heading1 font-primary"> FAQ </h1>
                    </div>
                    
                    <Accordion>

                        <Accordion.Item eventKey="0" >
                            <Accordion.Header>
                                <div className="row w-100 mr-3" >
                                    <div className="col" > Why Nuns? </div>
                                    <div className="col-auto text-right" > <span className="float-right" > </span > </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                
                                Because the Nuns represent hope and have played an important role throughout history. Nuns have played an important role throughout history. Their public identity was formed by the building of schools, hospitals and providing services to the poor and vulnerable.
                                <br/>
                                <br/>
                                Their habits signifies a badge of honor and a license to do good work.
                                <br/>
                                Nuns are well respected and welcomed members of their communities.

                                
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1" >
                            <Accordion.Header>
                                <div className="row w-100 mr-3" >
                                    <div className="col" > And now you ask why Crypto Nuns?! </div>
                                    <div className="col-auto text-right" > <span className="float-right" > </span > </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                
                                Our vision for CryptoNuns is that they also create a new foundation for modern NFTs. 
                                <br/>
                                Their qualities and their way of doing things is a bit different from classic Nuns, but that is what makes them special!
                                
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2" >
                            <Accordion.Header>
                                <div className="row w-100 mr-3" >
                                    <div className="col" > Why should I buy a Nun? </div>
                                    <div className="col-auto text-right" > <span className="float-right" > </span > </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                
                                First of all because of the amazing artwork! :-) Moreover you will be eligible to access the Nun's origin story, plus you will be able to take in the raffle to earn a spot in the sales of the comic book and its acts.We have also many surprises up our sleeves that we cannot reveal yet 😉
                                
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3" >
                            <Accordion.Header>
                                <div className="row w-100 mr-3" >
                                    <div className="col" > How much will a CryptoNun cost? </div>
                                    <div className="col-auto text-right" > <span className="float-right" > </span > </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                
                                Since @IndieNftArtwork is a community project, we have decided to reward holders of @IndieNftArtwork NFTs and collaboration partners, based on snapshots we will be taking.
                                <br/><br/>
                                The pricing is as follows: <br/>
                                - 0,49 $eGLD for IndieNftArtwork holders <br/>
                                - 0,59 $eGLD for IndieNftArtwork collaboration holders  <br/>
                                - 0,69 $eGLD 

                                
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="4" >
                            <Accordion.Header>
                                <div className="row w-100 mr-3" >
                                    <div className="col" > Why are there no dates or deadlines in the roadmap? </div>
                                    <div className="col-auto text-right" > <span className="float-right" > </span > </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                
                                Because we are not willing to sacrifice quality over time.
                                
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="5" >
                            <Accordion.Header>
                                <div className="row w-100 mr-3" >
                                    <div className="col" > What are the royalties?  </div>
                                    <div className="col-auto text-right" > <span className="float-right" > </span > </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                
                                10% royalties 
                                
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                    
				</div>
                	
 			</div> 
		</div>
	</section>
    */}
    
    
    
    <section className="section-gradient pt-4 pb-3 ">
		<div className="container p-0">
			<div className="row ">
                
                <div className="col-12 section-title ">
                    <h1 className="text-center text-700 mt-5 heading1 font-primary"> Partners </h1>
                </div>
                
				<div className="col-12 p-5 m-0 footer-partners" >
                    <div className="container pt-2 pb-2">

                        <div className="row mt-5 pt-5" >
                            <div className="col-6 col-md-3 mt-5 mt-md-5 text-center" > <a href="https://beskardao.org" target="_blank" rel="noreferrer" > <img src="./partners/beskardao.png" /> </a> </div>
                            <div className="col-6 col-md-3 mt-5 mt-md-5 text-center" > <a href="https://cybers.tech" target="_blank" rel="noreferrer" > <img src="./partners/cybers.png" /> </a> </div>
                            <div className="col-6 col-md-3 mt-5 mt-md-5 text-center" > <a href="https://founding8.com" target="_blank" rel="noreferrer" > <img src="./partners/founding8.jpeg" /> </a> </div>
                            <div className="col-6 col-md-3 mt-5 mt-md-5 text-center" > <a href="https://www.naturalborndegenz.art/" target="_blank" rel="noreferrer" > <img src="./partners/naturaldegenz.png" /> </a> </div>
                            <div className="col-6 col-md-3 mt-5 mt-md-5 text-center" > <a href="https://octopodsnft.com" target="_blank" rel="noreferrer" > <img src="./partners/octopods.png" /> </a> </div>
                            <div className="col-6 col-md-3 mt-5 mt-md-5 text-center" > <a href="https://plata.network" target="_blank" rel="noreferrer" > <img src="./partners/logo_plata.png" /> </a> </div>
                            <div className="col-6 col-md-3 mt-5 mt-md-5 text-center" > <a href="https://erdnft.com" target="_blank" rel="noreferrer" > <img src="./partners/erdnft-01.png" /> </a> </div>
                            <div className="col-6 col-md-3 mt-5 mt-md-5 text-center" > <a href="https://chaingear.io" target="_blank" rel="noreferrer" > <img src="./partners/chaingear.jpeg" /> </a> </div>
                        </div>
                    </div>
                </div>
                	
 			</div> 
		</div>
            
        <div className="w-100 horizontal-line mt-5"></div>    
            
	</section>
    
    {/*
    <section className="">
		<div className="container p-0">
			<div className="row ">
                
				<div className="col-12 col-md-6 section-image text-left ">
                    <div className="row" >
                        <div className="col"> <Link to="/token" > <img src="./plata.jpg" className="axonometric-right" /> </Link> </div>
                        <div className="col"> <Link to="/token" > <img src="./aidriver.jpg" className="axonometric-right" /> </Link> </div>
                    </div> 
				</div>
                
                <div className="col-12 col-md-6 text-justify mt-5 mt-md-0">
				    
                    <div className="col-12 section-title ">
                        
                        <div className=" w-100 d-block text"> <span className="badge badge-secondary text-uppercase">ESDT</span> </div>
                        
                        <h1 className="text-left text-700 mt-3 heading4 font-primary"> $PLATA TOKEN </h1>
                        <p className="mt-4 color-gray text">
                            <strong> $PLATA </strong> is an ESDT (Elrond Standard Digital Token) and represents the utility token of Plata Network, being as fast, secure and scalable as the native $eGLD coin itself.
                        </p>
                                        
                        <br/>
                        <a href="https://stake.plata.network" className="mybtn btn-bordered-white pt-2 pb-2 mr-2"  target="_blank" rel="noreferrer"  > Stake $PLATA </a>                                                
                        <a href="https://maiar.exchange/" className="mybtn btn-bordered-white pt-2 pb-2 mr-2" target="_blank" rel="noreferrer" > Swap to $PLATA </a>
                        
                        <Link to="/token" className="mybtn btn-bordered-white pt-2 pb-2 mr-2" > More </Link>
                                        
                    </div>
                    
                    
				</div>
                	
 			</div> 
		</div>
	</section>
    
	<section className="section-gradient">
		<div className="container p-0">
			<div className="row ">
                
				<div className="col-12 col-md-6 section-image text-left ">
                    <div className="row" >
                        <div className="col"> <Link to="/nft" > <img src="./driverone1.jpg" className="axonometric-left" /> </Link> </div>
                        <div className="col"> <Link to="/nft" > <img src="./drivertwo.jpg" className="axonometric-left" /> </Link> </div>
                    </div> 
				</div>
                
                <div className="col-12 col-md-6 text-justify mt-5">
				   
                    <div className="col-12 section-title ">
                        
                        <div className="w-100 d-block text"> <span className="badge badge-secondary text-uppercase">NFT</span> </div>
                        
                        <h1 className="text-left text-700 mt-3 heading4 font-primary"> Non-Fungible Tokens </h1>
                        <p className="mt-4 color-gray text">
                            We publish NFTs exclusively on the Elrond blockchain. Highly detailed hand drawn artworks representing drivers, racing cars and motor sports related content, are available on our platforms, and also on the Elrond marketplaces.
                        </p>
                    
                        <br/>
                        <Link to="/dashboard" className="mybtn btn-bordered-white pt-2 pb-2 mr-2" > NFT Stake </Link>
                        
                        <Link to="/nft" className="mybtn btn-bordered-white pt-2 pb-2 " > $PLATA Market </Link>
                                                                                            
                    </div>
                    
                    
				</div>
                	
 			</div> 
		</div>
	</section>
    
	<section className="section-gradient">
		<div className="container p-0">
			<div className="row ">
                
				<div className="col-12 col-md-6 section-image text-left ">
                    <div className="row" >
                        <div className="col"> <Link to="/power" > <img src="./racing2.jpg" className="axonometric-right" /> </Link>  </div>
                        <div className="col"> <Link to="/power" > <img src="./racing1.jpg" className="axonometric-right" /> </Link>  </div>
                    </div> 
				</div>
                
                <div className="col-12 col-md-6 text-justify mt-5 mt-md-0">
				   
                    <div className="col-12 section-title ">
                        
                        <div className=" w-100 d-block text"> <span className="badge badge-secondary text-uppercase">RACING</span> </div>
                        
                        <h1 className="text-left text-700 mt-3 heading4 font-primary"> Plata Racing </h1>
                        <p className="mt-4 color-gray text">
                            We are exploring to develop a P2E (play to earn) Racing game, where published NFTs are integrated and $PLATA is the main energy token. Learn more about the Plata Power functionality, which is already deployed.
                            
                            
                        </p>
                        
                        <br/>
                        <Link to="/power" className="mybtn btn-bordered-white pt-2 pb-2 " > Plata Power </Link>
                                
                    </div>
                    
                    
				</div>
                	
 			</div> 
		</div>
	</section>

    
    
	<section className="section-gradient pt-4 pb-3 bg-black">
		<div className="container p-0">
			<div className="row ">
                
				<div className="col-12 p-5 m-0 footer-partners" >
                    <div className="container pt-2 pb-2">

                        <div className="col-12 mt-2 mb-5" > <h2 className="text-center mt-4 heading4 font-primary"> ESDT/Racing Development and NFT Collaborations include </h2> </div>    

                        <div className="row mt-5 pt-5" >
                            <div className="col-6 col-md mt-5 mt-md-0 text-center" > <a href="https://erdnft.com" target="_blank" rel="noreferrer" > <img src="./partners/erdnft.png" /> </a> </div>
                            <div className="col-6 col-md mt-5 mt-md-0 text-center" > <a href="https://cybers.tech" target="_blank" rel="noreferrer" > <img src="./partners/cybers.png" /> </a> </div>
                            <div className="col-6 col-md mt-5 mt-md-0 text-center" > <a href="https://www.moonlorian.com" target="_blank" rel="noreferrer" > <img src="./partners/moonlorian.png" /> </a> </div>
                            <div className="col-6 col-md mt-5 mt-md-0 text-center" > <a href="https://www.indienftartwork.com" target="_blank" rel="noreferrer" > <img src="./partners/IndieNFTArtwork_logo.png" /> </a>  </div>
                            <div className="col-6 col-md mt-5 mt-md-0 text-center" > <a href="https://www.elvanft.com/" target="_blank" rel="noreferrer" > <img src="./partners/elva.png" /> </a>  </div>
                        </div>
                    </div>
                </div>
                
				<div className="col-12 p-5 m-0 footer-partners" >
                    <div className="container pt-0 pb-1">

                        <div className="col-12 mt-1 mb-2" > <h2 className="text-center mt-3 heading4 font-primary"> The blockchain is yours </h2> </div>    

                    </div>
                </div>
                	
 			</div> 
		</div>
            
        <div className="w-100 horizontal-line mt-5"></div>    
            
	</section>
    
    */}
    
    </>
  );
};

export default Home;

/*
<div className='d-flex flex-fill align-items-center container'>
      <div className='row w-100'>
        <div className='col-12 col-md-8 col-lg-5 mx-auto'>
          <div className='card shadow-sm rounded p-4 border-0'>
            <div className='card-body text-center'>
              <h2 className='mb-3' data-testid='title'>
                {dAppName}
              </h2>

              <p className='mb-3'>
                This is an Elrond dapp sample.
                <br /> Login using your Elrond wallet.
              </p>

              <Link
                to={routeNames.unlock}
                className='btn btn-primary mt-3 text-white'
                data-testid='loginBtn'
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
*/