import React from 'react';
import { sendTransactions } from "@elrondnetwork/dapp-core/services";
import { useTrackTransactionStatus } from '@elrondnetwork/dapp-core/hooks';
import { INFT } from 'contexts/Web3Context';
import { getTransaction } from 'apiRequests';
import { scAddress } from 'config';
import BigNumber from 'bignumber.js';

export default function useSendTransactionWrapper({
    onSuccess,
    onFail,
    onCancel
}: {
    onSuccess: (mintedNfts: string[]) => void;
    onFail: () => void;
    onCancel: () => void;
}) {
    const [sessionId, setSessionId] = React.useState<string>(null);

    //TODO: add break in while after 10 tries
    const getNftIdentifiersFromTransactionResult = async (txHash: string) => {
        let arr: string[] = [];
        let mintOperations = [];
        while (mintOperations.length === 0) {
            console.log('tx hash', txHash);
            wait(500);
            let txResult = await getTransaction(txHash);
            if (!txResult.data?.operations || txResult.data.operations.length === 0) {
                continue;
            }
            mintOperations = txResult.data.operations.filter(o => o.action === 'create');
            mintOperations.map(mo => mo.identifier
                //  {
                // const item: INFT = {
                //     identifier: mo.identifier
                // };
                // return item;
            // }
            ).forEach((item) => arr.push(item));
            console.log('Operations mint callback', mintOperations);
            console.log('Mint result', arr);
        }
        return arr;
    }

    const wait = (ms) => {
        return new Promise((resolve) => { setTimeout(resolve, ms) });
    }
    const onTransactionSuccessful = async (transactionId: string) => {
        let nfts = await getNftIdentifiersFromTransactionResult(transactionStatus.transactions[0].hash);
        onSuccess(nfts);
    };
    const onTransactionFailed = () => {
        console.log('Failed');
    };
    const onTransactionCancelled = () => {
        console.log('Cancelled');
    };

    const transactionStatus = useTrackTransactionStatus({
        transactionId: sessionId,
        onSuccess: onTransactionSuccessful,
        onFail: onTransactionFailed,
        onCancelled: onTransactionCancelled
    });

    const Mint = async (quantity: number, mintPrice: number) => {
        let hexQty = quantity.toString(16);
        if (hexQty.length % 2 === 1) {
            hexQty = '0' + hexQty;
        }
        let value = new BigNumber(mintPrice).multipliedBy(new BigNumber(10).pow(18)).multipliedBy(quantity);
        const { sessionId, error } = await sendTransactions({
            transactions: [
                {
                    value: value.toString(),
                    data: `buy@01@${hexQty}`,
                    receiver: scAddress,
                    gasLimit: (quantity + 1) * 15_000_000
                }
            ],
        });
        setSessionId(sessionId);
        return sessionId;
    };

    return {
        sendMintTransaction: Mint
    };
};