/* eslint-disable */
import React from 'react';
// import { DappProvider } from '@elrondnetwork/dapp-core';
import { DappProvider } from '@elrondnetwork/dapp-core/wrappers';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import { network, walletConnectBridge, walletConnectDeepLink } from 'config';
import PageNotFound from 'pages/PageNotFound';
import { routeNames } from 'routes';
import routes from 'routes';
// import '@elrondnetwork/dapp-core/build/index.css';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@elrondnetwork/dapp-core/UI';
import { Web3Provider } from 'contexts/Web3Context';

const App = () => {
  return (
    <Router>
      <DappProvider
        environment={'mainnet'}
        customNetworkConfig={{ network, walletConnectBridge, walletConnectDeepLink }}
      >
        <Web3Provider>
          <Layout>
            <TransactionsToastList />
            <NotificationModal />
            <SignTransactionsModals className='custom-class-for-modals' />
            <Routes>

              {routes.map((route: any, index: number) => (
                <Route
                  path={route.path}
                  key={'route-key-' + index}
                  element={<route.component />}
                />
              ))}
              <Route path='*' element={<PageNotFound />} />
            </Routes>
          </Layout>
        </Web3Provider>
      </DappProvider>
    </Router >
  );
};

export default App;
