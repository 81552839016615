import { useGetAccountInfo, useGetLoginInfo } from '@elrondnetwork/dapp-core/hooks';
import { refreshAccount } from '@elrondnetwork/dapp-core/utils';
import { getSignificantAccountNfts } from 'apiRequests';
import useSendMintTransaction from 'hooks/useSendMintTransaction';
import React, { Dispatch, SetStateAction } from 'react';
import { getPriceTag, getRemainingMints, getUserPurchasedQuantity, getUserRemainingPurchaseAmount } from 'scRequests';
import BigNumber from 'bignumber.js';

export interface INFT {
    identifier: string;
};
export enum MintStatus {
    NotStarted = 0,
    Pending = 1,
    FetchingNFts = 2,
    Successful = 3,
    Error = 4
}
export interface IWeb3Context {
    walletNfts: any[];
    mintPrice: number,
    remainingMints: number,
    remainingPossibleMints: number,
    freshMintedNfts?: any[];
    hasNewMints: boolean;
    refreshState?: () => void;
    mintNfts?: (quantity: number) => Promise<void>;
    hasBalanceToMintNfts?: (qty: number) => boolean;
    hasEnoughAllowanceToMintNfts?: (qty: number) => boolean;
    clearFreshMintedNfts?: () => void;
    mintStatus: MintStatus;
}

export const defaultState = {
    mintPrice: 0,
    remainingMints: 0,
    remainingPossibleMints: 0,
    hasNewMints: false,
    walletNfts: [],
    mintStatus: MintStatus.NotStarted,
};

export const Web3Context = React.createContext<IWeb3Context>(defaultState);

export const Web3Provider = ({ children }: { children: React.ReactNode }) => {
    const { account: { address, balance } } = useGetAccountInfo();
    const { isLoggedIn } = useGetLoginInfo();

    const [walletNfts, setWalletNfts] = React.useState<any[]>([]);
    const [mintPrice, setMintPrice] = React.useState(0);
    const [newlyMinted, setNewlyMinted] = React.useState([]);
    const [hasNewMints, setHasNewMints] = React.useState(false);
    const [remainingMints, setRemainingMints] = React.useState(0);
    const [remainingPossibleMints, setRemainingPossibleMints] = React.useState(0);
    const [mintStatus, setMintStatus] = React.useState<MintStatus>(MintStatus.NotStarted);
    const [failFallback, setFailCallback] = React.useState<() => void>();


    const onMintSuccess = async (mintedNfts: string[]) => {
        console.log('looking up identifiers: ', mintedNfts);
        const nfts = await refreshState();
        if (nfts === undefined) {
            window.alert('An error occured while fetching account NFTs. Please refresh the page and check your wallet for mint results');
        }
        let filtered = nfts.filter(wnft => mintedNfts.includes(wnft.identifier));
        setNewlyMinted(filtered);
        setHasNewMints(filtered.length > 0);
    };

    const { sendMintTransaction } = useSendMintTransaction(
        {
            onSuccess: onMintSuccess,
            onFail: () => failFallback,
            onCancel: () => failFallback,
        }
    );

    const mintNfts = async (quantity: number) => {
        await sendMintTransaction(quantity, mintPrice);
    };

    React.useEffect(() => {
        if (!isLoggedIn) {
            setMintPrice(0.69);
            return;
        }
        getPriceTag(address).then((price) => setMintPrice(price));
    }, [isLoggedIn]);

    React.useEffect(() => {
        setHasNewMints(newlyMinted?.length > 0);
    }, [newlyMinted]);

    const refreshState = async () => {
        setRemainingMints(await getRemainingMints());
        setRemainingPossibleMints(await getUserRemainingPurchaseAmount(address));
        if (!isLoggedIn) return [];
        const nfts = await getAndSetWalletNfts();
        await refreshAccount();
        return nfts;
    };

    const getAndSetWalletNfts = async () => {
        if (!isLoggedIn) return;
        const nfts = await getSignificantAccountNfts(address);
        setWalletNfts(nfts.data);
        return nfts.data;
    };

    const hasBalanceToMintNfts = (quantity: number) => {
        let mintingPrice = mintPrice * quantity;
        let parsedBalance = new BigNumber(balance).dividedBy(new BigNumber(10).pow(18)).toNumber();
        return parsedBalance > mintingPrice;
    };

    const hasEnoughAllowanceToMintNfts = (quantity: number) => {
        return quantity <= remainingPossibleMints;
    };

    React.useEffect(() => {
        refreshState().then((_) => {
            console.log('initialized');
        });
    }, [isLoggedIn]);

    return (
        <Web3Context.Provider
            value={{
                walletNfts,
                mintPrice,
                remainingMints,
                remainingPossibleMints,
                refreshState,
                mintNfts,
                hasNewMints,
                freshMintedNfts: newlyMinted,
                clearFreshMintedNfts: () => {
                    setNewlyMinted([]);
                    setMintStatus(MintStatus.NotStarted);
                },
                mintStatus,
                hasBalanceToMintNfts,
                hasEnoughAllowanceToMintNfts,
            }}
        >
            {children}
        </Web3Context.Provider>
    );
};