/* eslint-disable */
import React, {useState} from 'react';

const Collection = () => {
    
  React.useEffect(() => {
    
        
            
  }, []);

  return (
    <>
        <div className="">
            <div className="container">
                <div className="row ">

                    <div className="col-12 text-center">
                        <h1 className="font-weight-bold heading2"> Business Vision / Roadmap </h1>
                    </div>

                </div> 
            </div>
        </div>
        <div className='container'>

            <div className="row mt-5 text-center" >
                
                <div className="color-white text text-justify col-12 col-md-10 offset-md-1" >
      
                    <h3 className="heading4 " > BUSINESS VISION  </h3>
                    <br/>
                    Our business vision for the first year is focused on building income streams from three main activities related to art:
                    <br/><br/>
                    1. Using our own generated royalties in Elrond DeFi ecosystem  
                    <br/>
                    2. Providing tools and services that facilitates blockchain integration to physical art galleries and auction houses 
                    <br/>
                    3. Using e-commerce through an online store that sells items for any open Metaverse in Web3
                    <br/><br/>
                    All these activities aim (1) to secure the funds for our brand and business development and (2) to generate passive income for our holders.
                    <br/><br/>
      
                    <h4 className="heading5 font-400" >I. Royalties</h4>
                    
                    <br/>
                    A. CryptoNuns 10k collection  <br/>
                    B. CryptoNuns Comic book - 10 Editions released over 14 months 
                    <br/><br/>
                    - 2 chapters/character X 4 characters (8 editions) <br/>
                    - final chapters (2 editions)
                    <br/><br/>

                    <div className="light-box" >
                        Notes:<br/>
                        Since among our targeted audiences genuine collectors are an important category, we decided to airdrop several of these editions. <br/>
                        More details TBA. <br/>
                        We're open to discuss some aspects of the strategy regarding the release of comic book series together with our community   <br/>
                    </div>
    
                    <br/><br/>
                            
                    <h4 className="heading5 font-400" > II. Tools and services for blockchain integration by physical art galleries and auction houses  </h4>
                    
                    <br/>
                    - DAPPs and on-demand services for issuing certificates of authenticity for artworks sold/auctioned <br/>
                    - online marketplace for physical artworks centered in the beginning on EGLD as the main payment option 
                        
                    <br/><br/>
                        
                    <h4 className="heading5 font-400" >III. Online store for open Metaverse items </h4>
                    
                    <br/>
                    - in-house development of the store 
                    - our own design team creating the items listed for sale 
                    <br/>
                    <br/>
                    <br/>
                        
                    <h3 className="heading4 " > ESTIMATED ROADMAP  </h3>
                    <br/>
                        
                    <h4 className="heading5" >A. Staking - Q4 2022 (one month after mint)</h4>
    
                    <br/>
                    Rewards: <br/>
                    30% from CryptoNuns mint revenues <br/>
                    20% of monthly royalties from CryptoNuns <br/>
                    20% of monthly royalties from Comic book
                    <br/><br/>
                        
                    <h4 className="heading5" >B. Comic book Edition 1  Release - Q4 2022</h4>
                    
                    1.000 copies  <br/>
                    Raffle aidrop  <br/> <br/>

                    <h4 className="heading5" >C. Comic book Edition 2 Release - Q4 2022 /Q1 2023</h4>
                    
                    1.000 copies  <br/>
                    est. price - 0.49 EGLD 
                     <br/> <br/>
                        
                    <div className="light-box" >
                        Note: <br/>
                        Next editions will be released at an interval of 40-50 days of each other. <br/>
                        Details regarding the ratio of paid/airdrop TBA and also subject of open discussions with our community
                    </div>
                    
                     <br/>
                    <h4 className="heading5" >D. Tools and services for blockchain integration by physical art galleries and auction houses - Q3 2023 </h4>
                    
                    - This deadline is related to our stock products. Depending on the sales activity, which is already an ongoing activity for us, some potential customized products could be released earlier
                     <br/> <br/>

                    <h4 className="heading5" >E.  Online store for open Metaverse items - Q3 2023  </h4>
                    
                    - considering that Metaverse is still in early stages (as a concept but also from an operational point of view) and a fluid topic, subject to possible sudden structural changes, we focus for now strictly on the marketplace and the design of the most common/broader categories of items that are most likely to be used

                </div>
    
            </div>

        </div>
    </>
  );
};

export default Collection;
/*
<div className="col-12 col-md-4 offset-md-3 d-none" >
                        <PieChart data={pieData} label={({ dataEntry }) => dataEntry.value + "%" } startAngle={90} labelStyle={{ "fontSize": '5px' }} />  
                    </div>

                    <div className="col-12 col-md-4 text-left pt-5 pl-5 d-none" >
                        {tokenomics && tokenomics?.length > 0 && tokenomics.map((item, i) => {
                            return (
                                <div className="row mt-2 mb-1" key={i} >
                                    <div className="col-12" > 
                                        <span className="small-box" style={{background: item.color }}  ></span>
                                        {item.name} { item.suplyPercentage }% 
                                    </div>
                                </div>
                            );
                        })}
                    </div>
*/