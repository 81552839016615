/* eslint-disable */
import React, {useState} from 'react';

const Collection = () => {
    
  React.useEffect(() => {
    
        
            
  }, []);

  return (
    <>
        <div className="">
            <div className="container">
                <div className="row ">

                    <div className="col-12 text-center">
                        <h1 className="font-weight-bold" > Terms and Conditions </h1>
                    </div>

                </div> 
            </div>
        </div>
        <div className='container'>

            <div className="row mt-5 text font-400" >
                    
                CryptoNuns NFT is a collection of PFP  digital artworks (NFTs) running on the Elrond Network blockchain. This website is only an interface for introducing the project. Users are entirely responsible for the safety and management of their own private Elrond Network wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the CryptoNuns smart contract runs on the Elrond Network blockchain, there is no ability to undo, reverse, or restore any transactions.
                <br/><br/>
                This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you are accepting sole responsibility for any an all transactions involving CryptoNuns digital collectibles.
                <br/><br/>
                <h2 className="font-weight-bold" > Ownership </h2>
                <br/>
                i. You Own the NFT. Each CryptoNuns is an NFT on the Elrond Network blockchain. When you purchase an NFT, you own the underlying CryptoNuns, the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract and the Elrond Network : at no point may we seize, freeze, or otherwise modify the ownership of any CryptoNuns.

                ii. Personal Use. Subject to your continued compliance with these Terms, CryptoNuns grants you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely forthe following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your CryptoNuns NFT, provided that the marketplace cryptographically verifies each CryptoNuns owner’s rights to display the Art for their CryptoNuns to ensure that only the actual owner can display the Art; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your CryptoNuns, provided that the website/application cryptographically verifies each CryptoNuns owner’s rights to display the Art for CryptoNuns to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the CryptoNuns leaves the website/application.
      
            </div>

        </div>
    </>
  );
};

export default Collection;
/*
<div className="col-12 col-md-4 offset-md-3 d-none" >
                        <PieChart data={pieData} label={({ dataEntry }) => dataEntry.value + "%" } startAngle={90} labelStyle={{ "fontSize": '5px' }} />  
                    </div>

                    <div className="col-12 col-md-4 text-left pt-5 pl-5 d-none" >
                        {tokenomics && tokenomics?.length > 0 && tokenomics.map((item, i) => {
                            return (
                                <div className="row mt-2 mb-1" key={i} >
                                    <div className="col-12" > 
                                        <span className="small-box" style={{background: item.color }}  ></span>
                                        {item.name} { item.suplyPercentage }% 
                                    </div>
                                </div>
                            );
                        })}
                    </div>
*/