import { dAppName } from 'config';
import withPageTitle from './components/PageTitle';

import Home from './pages/Home';
import Connect from './pages/UnlockPage';

import Collection from './pages/Collection';
import Staking from './pages/Staking';
import Rarity from './pages/Rarity';
import Mint from './pages/Mint';
import ComicBook from './pages/Comic-book';
import Story from './pages/Story';
import About from './pages/About';
import Vision from './pages/Vision';
import Terms from './pages/Terms';

//import Power from './pages/Power';
import Wallet from './pages/Wallet';
// import Whitelist from 'pages/Whitelist';

export const routeNames = {
  home: '/',
  Collection: '/collection',
  Staking: '/staking',
  Rarity: '/rarity',

  Wallet: '/wallet',
  transaction: '/transaction',
  connect: '/connect',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  // whitelist: '/whitelist'
};

const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.Collection,
    title: 'Collection',
    component: Rarity
  },
  {
    path: routeNames.Staking,
    title: 'Staking',
    component: Staking
  },
  {
    path: routeNames.Rarity,
    title: 'Rarity',
    component: Rarity
  },
  {
    path: routeNames.Wallet,
    title: 'Wallet',
    component: Wallet
  },
  {
    path: routeNames.connect,
    title: 'Connect',
    component: Connect
  },
  {
    path: '/mint',
    title: 'Mint',
    component: Mint,
    //authenticatedRoute: true
  },
  {
    path: '/story',
    title: 'Story',
    component: Story,
    //authenticatedRoute: true
  },
  {
    path: '/about-nuns',
    title: 'About',
    component: About,
    //authenticatedRoute: true
  },
  {
    path: '/business-vision',
    title: 'Vision',
    component: Vision,
    //authenticatedRoute: true
  },
  {
    path: '/comic-book',
    title: 'ComicBook',
    component: ComicBook,
    //authenticatedRoute: true
  },
  {
    path: '/t-and-c',
    title: 'Terms',
    component: Terms,
    //authenticatedRoute: true
  },
  // {
  //   path: routeNames.whitelist,
  //   title: 'Whitelist',
  //   component: Whitelist
  // }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} | ${dAppName}`
    : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
