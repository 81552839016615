import React from 'react';
// import { getIsLoggedIn, DappUI, useGetAccountInfo } from '@elrondnetwork/dapp-core';
import { routeNames } from 'routes';
import { useLocation } from 'react-router-dom';
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton
} from '@elrondnetwork/dapp-core/UI';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';

export const UnlockRoute: () => JSX.Element = () => {
  const { address } = useGetAccountInfo();
  const { search } = useLocation();
  const isLoggedIn = Boolean(address);
  React.useEffect(() => {
    if(isLoggedIn){
      if(search){
        window.location.href = "/" + search.replace("?from=", "");
      }else{
        window.location.href = "/wallet";
      }
    }
  }, [isLoggedIn]);

  return (
    <div className='container connect-page' data-testid='unlockPage'>
        
        <div className="col-12 text-center" >
            <h2 className='mb-4 font-weight-bold'>Connect wallet</h2>
        </div>
        
        <div className="row mt-5" >
        
            <div className="col-12 col-md-6 mt-2 mb-2" >
                <div className='card text-center bg-black'>
                  <div className='card-body'>

                    <img className="avatar-lg" src="https://www.erdnft.com/img/maiar-32x32.png" alt="" />
                    <h4 className="mb-0 mt-4 font-weight-bold">Maiar</h4>
                    <p>Connect using the Maiar Wallet</p>

                    <WalletConnectLoginButton className="mybtn btn-bordered-white text-center"
                      callbackRoute="/wallet"
                      loginButtonText={'Connect'}
                    />

                  </div>
                </div>
            </div>
        
            <div className="col-12 col-md-6 mt-2 mb-2" >
                <div className='card text-center bg-black'>
                  <div className='card-body'>

                    <img className="avatar-lg" src="https://www.erdnft.com/img/maiar-32x32.png" alt="" />
                    <h4 className="mb-0 mt-4 font-weight-bold">Maiar Extension</h4>
                    <p>Connect using the Browser Extension</p>

                    <ExtensionLoginButton className="mybtn btn-bordered-white text-center extensionLoginButton d-inline-block"
                      callbackRoute="/wallet"
                      loginButtonText={'Connect'}
                    />

                  </div>
                </div>
            </div>
        
            <div className="col-12 col-md-6 mt-2 mb-2" >
                <div className='card text-center bg-black'>
                  <div className='card-body'>

                    <img className="avatar-lg" src="https://www.erdnft.com/img/maiar-32x32.png" alt="" />
                    <h4 className="mb-0 mt-4 font-weight-bold">Web Wallet</h4>
                    <p>Connect using the Maiar Web Wallet</p>

                    <WebWalletLoginButton className="mybtn btn-bordered-white text-center"
                      callbackRoute="/wallet"
                      loginButtonText={'Connect'}
                    />

                  </div>
                </div>
            </div>
        
            <div className="col-12 col-md-6 mt-2 mb-2" >
                <div className='card text-center bg-black'>
                  <div className='card-body'>

                    <img className="avatar-lg" src="https://www.erdnft.com/img/maiar-32x32.png" alt="" />
                    <h4 className="mb-0 mt-4 font-weight-bold">Ledger Wallet</h4>
                    <p>Connect using the Ledger Wallet</p>
    
                    <LedgerLoginButton className="mybtn btn-bordered-white text-center"
                      loginButtonText={'Connect'}
                      callbackRoute="/wallet"
                    />

                  </div>
                </div>
            </div>
            
        </div>
        
    </div>
  );
};

export default UnlockRoute;

/*

<div className='home d-flex flex-fill align-items-center'>
  <div className='m-auto' data-testid='unlockPage'>
    <div className='card my-4 text-center'>
      <div className='card-body py-4 px-2 px-sm-2 mx-lg-4'>

        <h4 className='mb-4'>Login</h4>
        <p className='mb-4'>pick a login method</p>

        <ExtensionLoginButton
          callbackRoute={routeNames.dashboard}
          loginButtonText={'Extension'}
        />
        <WebWalletLoginButton
          callbackRoute={routeNames.dashboard}
          loginButtonText={'Web wallet'}
        />
        <LedgerLoginButton
          loginButtonText={'Ledger'}
          callbackRoute={routeNames.dashboard}
          className={'test-class_name'}
        />
        <WalletConnectLoginButton
          callbackRoute={routeNames.dashboard}
          loginButtonText={'Maiar'}
        />
      </div>
    </div>
  </div>
</div>

*/