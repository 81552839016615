/* eslint-disable */
import React, {useState} from 'react';

const Collection = () => {
    
  React.useEffect(() => {
    
        
            
  }, []);

  return (
    <>
        <div className="">
            <div className="container">
                <div className="row ">

                    <div className="col-12 text-center">
                        <h1 className="font-weight-bold"> Staking </h1>
                    </div>

                </div> 
            </div>
        </div>
        <div className='container'>

            <div className="row mt-5 text-center" >
                    
                <h5 className="h3" > Coming soon... </h5>
      
            </div>

        </div>
    </>
  );
};

export default Collection;
/*
<div className="col-12 col-md-4 offset-md-3 d-none" >
                        <PieChart data={pieData} label={({ dataEntry }) => dataEntry.value + "%" } startAngle={90} labelStyle={{ "fontSize": '5px' }} />  
                    </div>

                    <div className="col-12 col-md-4 text-left pt-5 pl-5 d-none" >
                        {tokenomics && tokenomics?.length > 0 && tokenomics.map((item, i) => {
                            return (
                                <div className="row mt-2 mb-1" key={i} >
                                    <div className="col-12" > 
                                        <span className="small-box" style={{background: item.color }}  ></span>
                                        {item.name} { item.suplyPercentage }% 
                                    </div>
                                </div>
                            );
                        })}
                    </div>
*/