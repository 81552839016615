/* eslint-disable */
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="color-white text-center">
        
        <div className="container mt-3">
            <div className="row mb-5 mt-5">
                <div className="col-12 text-center" > Made with ❤️ on Elrond Network by @IndieNftArtwork  </div>
            </div>
            <div className="row mb-5 mt-5">

                <div className="col-6 col-md">
                    <div className="widget">
                        <h3 className="text-500 text font-primary d-none"> Support </h3>
                        <ul className="footer-navigation list-unstyled color-gray-light">
                            <li className="menu-item">
                                <Link to="/collection" className="animated-border-bottom"> Collection </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-6 col-md">
                    <div className="widget">
                        <h3 className="text-500 text font-primary d-none"> Support </h3>
                        <ul className="footer-navigation list-unstyled color-gray-light">
                            <li className="menu-item">
                                <Link to="/staking" className="animated-border-bottom"> Staking </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-6 col-md">
                    <div className="widget">
                        <h3 className="text-500 text font-primary d-none"> Support </h3>
                        <ul className="footer-navigation list-unstyled color-gray-light">
                            <li className="menu-item">
                                <Link to="/rarity" className="animated-border-bottom"> Rarity </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-6 col-md">
                    <div className="widget">
                        <h3 className="text-500 text font-primary d-none"> Support </h3>
                        <ul className="footer-navigation list-unstyled color-gray-light">
                            <li className="menu-item">
                                <Link to="/t-and-c" className="animated-border-bottom"> Terms and Conditions </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>

        <div className="w-100 horizontal-line"></div>

        <div className="container">
            <div className="row justify-content-md-between mt-0 pt-3 pb-4 ">

                    <div className="col-md-8 d-inline-flex align-items-center justify-content-center justify-content-md-start">
                        <span className="main-footer__copyrights text-gray d-block text-small mr-0"> © 2022  CryptoNuns. All rights reserved. </span>
                    </div>

                    <div className="col-md-4 col-lg-auto c-mt-6 c-mt-md-0">
                        <ul className="social-icons list-unstyled d-flex justify-content-center justify-content-md-end mt-3">
                            
                            <li className="">
                                <a href="https://twitter.com/CryptoNunsNFT" className="social-link d-flex align-items-center justify-content-center" target="_blank" rel="noreferrer" >
                                    <i className="fab fa-twitter" ></i>
                                </a>
                            </li>
                            <li className="ml-4 d-none">
                                <a href="" className="social-link d-flex align-items-center justify-content-center" target="_blank" rel="noreferrer" >
                                    <i className="fab fa-instagram" ></i>
                                </a>
                            </li>
                            <li className="ml-4">
                                <a href="https://t.me/+BDWln4xrLt40N2Y0" className="social-link d-flex align-items-center justify-content-center" target="_blank" rel="noreferrer" >
                                    <i className="fab fa-telegram" ></i>
                                </a>
                            </li>
                            <li className="ml-4 d-none">
                                <a href="" className="social-link d-flex align-items-center justify-content-center" target="_blank" rel="noreferrer" >
                                    <i className="fab fa-youtube" ></i>
                                </a>
                            </li>
                            <li className="ml-4 d-none">
                                <a href="" className="social-link d-flex align-items-center justify-content-center" target="_blank" rel="noreferrer" >
                                    <i className="fab fa-discord" ></i>
                                </a>
                            </li>

                        </ul>

                    </div>
                </div>

        </div>
        
        {/*
        <audio controls autoPlay className="audio-song" >
          <source src="./audio/song.mp3" type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
        */}
        
    </footer>
  );
};

export default Footer;
