import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './assets/sass/theme.scss';

// ReactDOM.render(<App />, document.getElementById('root'));

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
