// import { NetworkType } from '@elrondnetwork/dapp-core';

export const decimals = 4;
export const denomination = 18;
export const gasPerDataByte = 1500;
export const timeout = 10000; // 10 sec

export const walletConnectBridgeAddresses: string[] = [
  'https://bridge.walletconnect.org'
];
export const walletConnectBridge: string =
  walletConnectBridgeAddresses[
  Math.floor(Math.random() * walletConnectBridgeAddresses.length)
  ];

export const walletConnectDeepLink =
  'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/';

export const dAppName = 'CryptoNuns';


// export const network = {
//   id: 'devnet',
//   name: 'Devnet',
//   egldLabel: 'xEGLD',
//   walletAddress: 'https://devnet-wallet.elrond.com',
//   apiAddress: 'https://devnet-api.elrond.com',
//   gatewayAddress: 'https://devnet-gateway.elrond.com',
//   explorerAddress: 'http://devnet-explorer.elrond.com',
//   graphQlAddress: 'https://devnet-exchange-graph.elrond.com/graphql'
// };


export const network = {
  id: 'mainnet',
  name: 'Mainnet',
  egldLabel: 'EGLD',
  walletAddress: 'https://wallet.elrond.com',
  apiAddress: 'https://elrond-api.blastapi.io/26cd0c84-f740-4bc4-b30b-3a466fb96f39',
  gatewayAddress: 'https://elrond-mainnet.blastapi.io/26cd0c84-f740-4bc4-b30b-3a466fb96f39',
  explorerAddress: 'http://explorer.elrond.com',
  graphQlAddress: 'https://exchange-graph.elrond.com/graphql',
  skipFetchFromServer: true,
};

// export const scAddress = 'erd1qqqqqqqqqqqqqpgq70uegt43hed0dme8xwx8lh40k9szc0e56pps3y6sxp';
export const scAddress = 'erd1qqqqqqqqqqqqqpgqwdd5hfua5h7lzuss84ssv9x7x29ayameyl5sslpwfq';

export const COLLECTION_TICKER = 'CNUN-8b89ee';
