/* eslint-disable */
import React, {useState} from 'react';
import { Web3Context } from 'contexts/Web3Context';
import Modal from 'react-modal';
import ReactDOM from 'react-dom';
import ImageLoad from '../Wallet/ImageLoad';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import { Link } from 'react-router-dom';
import moment from 'moment'

import { logout } from '@elrondnetwork/dapp-core/utils';

import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

let crytponuns_rarity_ranking = require('database/crytponuns_rarity_ranking.json');
let mintDate = "2022-09-09 14:00:00"

const Collection = () => {
    
    let isLoggedIn = Boolean(address);
    const { address, account } = useGetAccountInfo();
    const [quantity, setQuantity] = React.useState(1);
    const [isMinting, setMinting] = React.useState(false);

    const handleLogout = () => {
        logout(`${window.location.origin}/`);
      };
    
    const context = React.useContext(Web3Context);
    // context.setFailCallback(() => {  
    //     /*
    //     toast('Transaction was unsuccessfuls', {
    //         position: 'bottom-right',
    //         toastId: 'copy-address',
    //         transition: Slide,
    //         theme: 'dark'
    //         //className: 'foo-bar'
    //     });
    //     */
    // });
    // // context.setFailCallback(yourRevertFunction);

    const handleChangeQuantity = (e) => {
        
        //console.log( e.target.value )
        
        if( e.target.value != [] && parseInt( e.target.value ) > 20 ){
            toast('You cannot mint more than 20 NFTs', {
                position: 'bottom-right',
                toastId: 'copy-address',
                transition: Slide,
                theme: 'dark'
                //className: 'foo-bar'
            });
            
            setQuantity(1)
        }else{
            setQuantity(e.target.value)
        }
        
    }
    
    const handleMint = async () => {
        //console.log('address', account);
        //console.log('test', context);
        
        //console.log( context.hasBalanceToMintNfts(quantity) )
        //console.log( context.hasEnoughAllowanceToMintNfts(quantity) )
                
        if( quantity == 0 ){
            toast('Please set the quantity you want to mint.', {
                position: 'bottom-right',
                toastId: 'copy-address',
                transition: Slide,
                theme: 'dark'
                //className: 'foo-bar'
            });
        }
        
        if( context.hasBalanceToMintNfts(quantity) == true && context.hasEnoughAllowanceToMintNfts(quantity) == true ){
            
            setMinting(true)
            
            //TEST with local new mints
            //context.hasNewMints = true  
            
            //mint NFTs
            await context.mintNfts(parseInt(quantity));
            
        }else{
            
            if( context.hasBalanceToMintNfts(quantity) == false && context.hasEnoughAllowanceToMintNfts(quantity) == true ){
                
                toast('You do not have enough balance to mint '+quantity+' NFTs.', {
                    position: 'bottom-right',
                    toastId: 'copy-address',
                    transition: Slide,
                    theme: 'dark'
                    //className: 'foo-bar'
                });
                
            }else 
            if( context.hasBalanceToMintNfts(quantity) == true && context.hasEnoughAllowanceToMintNfts(quantity) == false ){
                
                toast('You are not allowed to mint '+quantity+' NFTs.', {
                    position: 'bottom-right',
                    toastId: 'copy-address',
                    transition: Slide,
                    theme: 'dark'
                    //className: 'foo-bar'
                });
                
            } 
            
        }
        
    };
    
      
    
    React.useEffect(() => {
                
        //console.log(context.freshMintedNfts);
        context.freshMintedNfts.forEach((item)=>{
            let ID = item.name.split("Nun #")[1]
            for( var j in crytponuns_rarity_ranking){
                if( j == ID ){
                    item.ranking = parseFloat( crytponuns_rarity_ranking[j] )
                }
            }
            item.thumb_url = './nfts/'+ID+'_comprimat.png'
            item.thumb_url = 'https://cdn.cryptonuns.io/images/'+ID+'_comprimat.png'
        })
        if( context.freshMintedNfts.length > 0 ){
            setMinting(false)
        }
        
    }, [context.hasNewMints]);
    

    return (
    <>
        
        <ReactTooltip />
        <ToastContainer transition={Slide} />
        
        <div className="">
            <div className="container">
                <div className="row ">

                    <div className="col-12 text-center">
                        <h1 className="font-weight-bold"> Mint prices/conditions/offers </h1>
                    </div>

                </div> 
            </div> 

        </div>
        <div className='container'>

            
        
            { !Boolean(address)  && 
                
                <div className="row mt-5 text-center" >
                    <h1 className="font-weight-bold"> Connect your wallet in order to mint </h1>
                    
                    <div className="col-12 col-md-4 offset-md-4 mt-5" >
                        <Link to="/connect" className="mybtn btn-primary pt-2 pb-2" > Connect Wallet </Link>    
                    </div>
             
                </div>
                
            }
            { Boolean(address)  && 
                <div className="row mt-5 text-center" >

                    <div className="row mt-5 text text-400 heading1" >
                        <div className='col-12 col-md-4 mt-4 heading4'>
                            Remaining: <span className="color-primary" > {context.remainingMints} </span>
                        </div>
                        <div className='col-12 col-md-4 mt-4 heading4'>
                            Remaining possible mints: <span className="color-primary" > {context.remainingPossibleMints} </span>
                        </div>
                        <div className='col-12 col-md-4 mt-4 heading4'>
                            Price tag: <span className="color-primary" > {context.mintPrice} EGLD </span>
                        </div>
                    </div>

                    

                    { moment.utc().format("YYYY-MM-DD HH:mm:ss") < mintDate && 
                    <div className="row mt-5 mb-5 text" >
                        <div className='col-12 text-center heading3'>
                            Mint starting at {mintDate}UTC
                        </div>
                    </div>
                    }

                    { moment.utc().format("YYYY-MM-DD HH:mm:ss") >= mintDate && 
                        <div className="row mt-5 text" >
                            <div className='col-12 col-md-4 offset-md-4'>
                                <input type={'number'} onChange={(e) => handleChangeQuantity(e) } min="1" max="20" className="form-control" value={quantity}  />
                            </div>
                        </div>
                    }
             
                    { moment.utc().format("YYYY-MM-DD HH:mm:ss") >= mintDate && 
                    <div className="row mt-2 mt-md-5 text" >
                        <div className='col-12 col-md-4 offset-md-4 text-center'>
                            { !isMinting &&  
                                <button className='mybtn btn-lg w-100 btn-bordered-white' onClick={handleMint}> Mint </button>
                            }
                            { isMinting &&  
                                <button className='mybtn btn-lg w-100 btn-primary btn-primary-disabled' disabled > Minting ... </button>
                            }
                        </div>
                    </div>
                    }
                
                    <div className="row mt-5 mb-4 text-center" >
                        <div className="col-12 col-md-4 offset-md-4" >
                            <a onClick={()=>{ handleLogout(); }} className="mybtn btn-bordered-white pt-2 pb-2" > Disconnect </a> 
                        </div>

                    </div>
                    

                    {/* {context.hasNewMints && (
                        <div className="row mt-5 text" >
                            {context.freshMintedNfts.map((nft, i) => (
                                <div key={i} className='col'>
                                    <img src={nft.url} />
                                    <p>{nft.name}</p>
                                </div>
                            ))}
                            <button className='btn btn-primary' onClick={() => context.clearFreshMintedNfts()}> Ok, awesome </button>
                        </div>
                    )} */}

                    <Modal
                        isOpen={context.hasNewMints}
                    >
                        <div className='container' >
                            <div className='row'>
                                <div className='col'>
                                    <h3 style={{ color: 'white' }} className="text-700" >Congrats! You've minted:</h3>
                                </div>
                            </div>
                            <div className="row mt-5 text overflow-auto modal-body-scrollable"  >
                                {context.freshMintedNfts.map((nft, i) => (
                                    <div key={i} className='col-sm-12 col-lg-3 mt-3 position-relative nft-item'>
                                        <a href={nft.url}  className="image-full-link" title="See full size image" target="_blank" > <i className="fa fa-image"></i> </a>
                                        <ImageLoad uri={nft.thumb_url} />
                                        <div className="row mt-1 mb-1" >
                                            <div className="col-6 font-weight-bold"  > {nft.name}  </div>
                                            <div className="col-6 text-right color-primary text-400" > Ranking:  {'#'+nft.ranking}  </div>
                                        </div>
                                    </div>
                                ))}
                                
                            </div>
                            <div className="col-12 col-md-4 offset-md-4 mt-3 text-center" > <button className='mybtn btn-primary w-100' onClick={() => context.clearFreshMintedNfts()}> Ok, awesome </button> </div>
                        </div>
                    </Modal>

                </div>
            }
            
            <div className="row mt-5 text-left" >

                <div className="col-12 col-md-8 offset-md-2 text text-justify" > 

                    <h3 className="heading4 " > 1.Price  </h3>
                    - 0,49 EGLD for IndieNftArtwork holders/Discord OG's/Discord OGG's/WL giveaway winners <br/>
                    - 0,59 EGLD for WL partnership giveaways/AMA's <br/>
                    - 0,69 EGLD public mint
                    <br/><br/>

                    <h3 className="heading4 " > 2.Limit  </h3>
                    - Maximum 20 Nuns/wallet
                    <br/><br/>

                    <h3 className="heading4 " > 3.Benefits  </h3>
                    - Buy 5 get 1 for FREE <br/>
                    - By holding at least one Nun you are eligibile to participate in the raffle to get a free drop of the first act from the Nuns Comic Book series (more NFTs will improve the chances to win)
                    <br/><br/>

                </div>

            </div>

        </div>
    </>
    );
};

export default Collection;
/*
<div className="col-12 col-md-4 offset-md-3 d-none" >
                        <PieChart data={pieData} label={({ dataEntry }) => dataEntry.value + "%" } startAngle={90} labelStyle={{ "fontSize": '5px' }} />  
                    </div>

                    <div className="col-12 col-md-4 text-left pt-5 pl-5 d-none" >
                        {tokenomics && tokenomics?.length > 0 && tokenomics.map((item, i) => {
                            return (
                                <div className="row mt-2 mb-1" key={i} >
                                    <div className="col-12" > 
                                        <span className="small-box" style={{background: item.color }}  ></span>
                                        {item.name} { item.suplyPercentage }% 
                                    </div>
                                </div>
                            );
                        })}
                    </div>
*/