import {
  AbiRegistry,
  Address,
  AddressValue,
  ResultsParser,
  SmartContract,
  SmartContractAbi,
  TokenIdentifierValue,
  U64Value
} from '@elrondnetwork/erdjs/out';
import {
  scAddress,
  network
} from 'config';
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import contractAbi from './abi/launchpad.abi.json';
import BigNumber from 'bignumber.js';

const Provider = new ProxyNetworkProvider(network.gatewayAddress, { timeout: 10000 });
const resultsParser = new ResultsParser();

let currentPort = '';
if (window.location.port != '') {
  currentPort = ':' + window.location.port;
}
const currentHostname =
  window.location.protocol + '//' + window.location.hostname + currentPort;

const getSmartContractObj = async () => {
  const abiRegistry = await AbiRegistry.create(formatAbiJson(contractAbi));
  const abi = new SmartContractAbi(abiRegistry, ["NftMinter"]);
  return new SmartContract({
    address: new Address(scAddress),
    abi: abi
  });
};

const formatAbiJson = (abi: any) => {
  return {
    name: abi.name,
    endpoints: abi.endpoints,
    types: abi.types
  };
};

export const getPriceTag = async (address: string) => {
  const saleIdArg = new U64Value(1);
  const tokenIdentifierArg = new TokenIdentifierValue("EGLD");
  const nonceArg = new U64Value(0);
  const addressArg = new AddressValue(new Address(address));

  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getPriceTag([saleIdArg, tokenIdentifierArg, nonceArg, addressArg]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(response, endpointDef);

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value.div(new BigNumber(10).pow(18)).toNumber();
  }
}


export const getRemainingMints = async () => {
  const saleIdArg = new U64Value(1);

  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.remaining([saleIdArg]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(response, endpointDef);

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    return value.toNumber();
  }
}

export const getUserPurchasedQuantity = async (address: string) => {
  const saleIdArg = new U64Value(1);
  const addressArg = new AddressValue(new Address(address));

  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getUserPurchaseHistory([saleIdArg, addressArg]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(response, endpointDef);

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    console.log("purchase history", value.purchased_quantity.toNumber());
    return value.purchased_quantity.toNumber();
  }
}

export const getUserRemainingPurchaseAmount = async (address: string) => {
  const saleIdArg = new U64Value(1);
  const addressArg = new AddressValue(new Address(address));

  const contract = await getSmartContractObj();
  const interaction = contract.methodsExplicit.getRemainingPurchasable([saleIdArg, addressArg]);
  const query = interaction.buildQuery();
  const response = await Provider.queryContract(query);
  const endpointDef = interaction.getEndpoint();
  const parsedResponse = resultsParser.parseQueryResponse(response, endpointDef);

  if (parsedResponse.returnCode.isSuccess()) {
    const value = parsedResponse.firstValue?.valueOf();
    console.log("purchase history", value.toNumber());
    return value.toNumber();
  }
}