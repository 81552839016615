import axios from 'axios';
import * as config from "../config";

interface GetLatestTransactionsType {
  apiAddress: string;
  address: string;
  contractAddress: string;
  timeout: number;
  page?: number;
  url?: string;
}

const fetchSignificantAccountNfts = () =>
  async function getSignificantAccountNfts(address: string) {
    try {
      const { data } = await axios.get(`${config.network.apiAddress}/accounts/${address}/nfts?collections=${config.COLLECTION_TICKER}&size=1000`, {
        timeout: 10000
      });

      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchTransaction = () =>
  async function getTransaction(txHash: string) {
    try {
      const { data } = await axios.get(`${config.network.apiAddress}/transactions/${txHash}`, {
        timeout: 10000
      });

      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchTransactions = (url: string) =>
  async function getTransactions({
    apiAddress,
    address,
    contractAddress,
    timeout
  }: GetLatestTransactionsType) {
    try {
      const { data } = await axios.get(`${apiAddress}${url}`, {
        params: {
          sender: address,
          receiver: contractAddress,
          condition: 'must',
          size: 25
        },
        timeout
      });

      return {
        data: data,
        success: data !== undefined
      };
    } catch (err) {
      return {
        success: false
      };
    }
  };

const fetchWalletNFTs = () =>
  async function getWalletNFTs({ address }) {
    try {
      const { data } = await axios.get(
        `${config.network.gatewayAddress}/address/${address}/esdt`
      );
      return {
        data: data,
        success: data !== undefined,
      };
    } catch (err) {
      return {
        success: false,
      };
    }
  };


export const getWalletNFTs = fetchWalletNFTs();
export const getTransaction = fetchTransaction();
export const getTransactions = fetchTransactions('/transactions');
export const getTransactionsCount = fetchTransactions('/transactions/count');
export const getSignificantAccountNfts = fetchSignificantAccountNfts();
