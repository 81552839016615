/* eslint-disable */
import { Web3Context } from 'contexts/Web3Context';
import React, {useState} from 'react';
import Modal from 'react-modal';
import ReactDOM from 'react-dom';
import ImageLoad from './ImageLoad';

import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import { routeNames } from 'routes';

import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';

import { getWalletNFTs } from '../../helpers/asyncRequests';
import { getSignificantAccountNfts } from '../../apiRequests';

let crytponuns_rarity_ranking = require('database/crytponuns_rarity_ranking.json');
let cryptonuns_rarity_scores = require('database/cryptonuns_rarity_scores.json');

let OFFICIAL_TICKER = "CNUN"; //starts with

const Wallet = () => {
    
    const { address, account } = useGetAccountInfo();
    const [walletNfts, setWalletNfts] = useState([]);
    const [loadedNfts, setLoadedNfts] = useState([]);
    const [listSize] = useState(19);
    const [limit, setLimit] = useState(20);
    
    
    const showMoreItems = () => {

        //console.log( walletNfts )
        //console.log( loadedNfts )
        
        let size = loadedNfts.length
        let array = loadedNfts
        walletNfts.forEach((item, i) => {
            if (i >= size && i <= size + listSize) {
                array.push(item)
            }
         })

        setLoadedNfts(array);

        let newLimit = limit + limit
        setLimit(newLimit)

    }
    
    const copyToClipboard = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(address);

        toast('Address copied to clipboard', {
            position: 'bottom-right',
            toastId: 'copy-address',
            transition: Slide,
            theme: 'dark'
            //className: 'foo-bar'
        });
    };
    
    const transformData = (nftItem) => {
        let tokenIdentifierSplit = nftItem.tokenIdentifier.split('-');
        return {
            attributes: Buffer.from(nftItem.attributes || '', 'base64').toString(),
            balance: parseInt(nftItem.balance),
            creator: nftItem.creator,
            owner: address,
            name: nftItem.name, //name
            nonce: nftItem.nonce,
            royalties: '${parseInt(nftItem.royalties) / 100} %',
            fullTokenIdentifier: nftItem.tokenIdentifier,
            //tokenIdentifier: '${tokenIdentifierSplit[0]}-${tokenIdentifierSplit[1]}',
            tokenIdentifier: tokenIdentifierSplit[0] + '-' + tokenIdentifierSplit[1],
            uris: nftItem.uris.map(u => Buffer.from(u, 'base64').toString())
        };
    };

    const loadWallet = (address) => {

        let parsed = [];
        //let walletNfts = getWalletNFTs({ address }).then(({ data }) => {
        let walletNfts = getSignificantAccountNfts( address ).then(({ data }) => {
            if (data != undefined) {
                /*
                let items = Object.entries(data.data.esdts).map(x => x[1]);
                for (var i = 0; i < items.length; i++) {
                    if(items[i].uris !== undefined)
                        parsed.push(transformData(items[i]));
                }
                */
                data.forEach((item)=>{ parsed.push(item) })
                return parsed;
            }
            return parsed;
        });
        return walletNfts;

    };
    
    React.useEffect(() => {
        const isLoggedIn = Boolean(address);

        if (!isLoggedIn) {
            window.location.href = '/connect';
        } else {
            //console.log(address);
                loadWallet( address )
                .then((val)=>{
                    let filtered = [];
                    val.forEach((item, i) => {

                        //console.log(item)
                        //
                        //console.log( item.tokenIdentifier )
                        //item.url = item.uris[0]
                        //item.thumb_url = item.uris[0]
                        item.url = item.url
                        item.thumb_url = item.url
                        if( item.ticker.includes( OFFICIAL_TICKER ) ){
                            
                            let ID = item.name.split("Nun #")[1]
                            //item.thumb_url = './nfts/'+ID+'_comprimat.png'
                            item.thumb_url = 'http://3.64.45.20/images/'+ID+'_comprimat.png'
                            item.thumb_url = 'https://cdn.cryptonuns.io/images/'+ID+'_comprimat.png'

                            for( var i in crytponuns_rarity_ranking){
                                if( i == ID ){
                                    item.ranking = crytponuns_rarity_ranking[i]
                                }
                            }
                            for( var j in cryptonuns_rarity_scores){
                                if( j == ID ){
                                    item.score = parseFloat( cryptonuns_rarity_scores[j].toFixed(2) )
                                }
                            }
                            
                        } 
                        //console.log(item)

                        filtered.push(item)
                    })

                    setWalletNfts(filtered);

                    let loaded = []
                    filtered.forEach((item, i) => {
                        if (i <= listSize) {
                            loaded.push(item)
                        }
                    })
                    setLoadedNfts(loaded);       
                })          
                
        }
    }, []);

    const context = React.useContext(Web3Context);
    // context.setFailCallback(yourRevertFunction);

    const handleMint = async () => {
        //console.log('test', context.mintPrice);
        await context.mintNfts(parseInt(quantity));
    };

    return (
        <>
            <div className="">
                <div className="row mt-3 mb-5">
                    <div className="col-12">

                        <div className="intro text-center">
                            <div className="intro-content text-center">

                                <h3 className="mt-3 mb-3 d-block">Elrond NFT Wallet Content</h3>

                                <div className="mt-5 mb-0 copy-box" data-tip={address} onClick={(e) => copyToClipboard(e)} >
                                    {address.substring(0, 10)}{address.length >= 16 && '...'}{address.substring(address.length - 6, address.length)}
                                </div>

                                <ReactTooltip />
                                <ToastContainer transition={Slide} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>

                <div className="row mt-5 text-center" >
                    
                {
                    loadedNfts.map((nft, i) => (
                        <div key={i} className='col-sm-12 col-lg-3' >
                            <div className="w-100 horizontal-line mb-4"></div>  
                            <div  className='col-10 offset-1 p-0 position-relative rarity-nft-item' >
                                <a href={nft.url}  className="image-full-link" title="See full size image" target="_blank" > <i className="fa fa-image"></i> </a>
                                <ImageLoad uri={nft.thumb_url} />                      
                                <p className="heading4" >{nft.name}</p>

                                <div className="row mb-0" >
                                    <div className="col-6 " > Ranking </div>
                                    <div className="col-6 text-right" > Score </div>
                                </div>

                                <div className="row mb-3" >
                                    { nft.ranking && <div className="col-6 color-primary heading5" > {'#'+nft.ranking} </div> }
                                    { nft.score && <div className="col-6 color-primary heading5 text-right" > {nft.score} </div> }
                                </div>


                            </div>
                            <div className="w-100 horizontal-line mb-4"></div>  
                        </div>
                    ))
                }

                {walletNfts.length > 0 && walletNfts?.length > loadedNfts?.length &&
                    <div className="col-12 text-center cursor-pointer mt-5"   >
                        <a className="mybtn btn-bordered-white pt-2 pb-2" onClick={showMoreItems} >
                            Load more
                        </a>
                    </div>
                }
      
            </div>        
        
            </div>
        </>
    );
};

export default Wallet;
/*
<div className="col-12 col-md-4 offset-md-3 d-none" >
                        <PieChart data={pieData} label={({ dataEntry }) => dataEntry.value + "%" } startAngle={90} labelStyle={{ "fontSize": '5px' }} />  
                    </div>

                    <div className="col-12 col-md-4 text-left pt-5 pl-5 d-none" >
                        {tokenomics && tokenomics?.length > 0 && tokenomics.map((item, i) => {
                            return (
                                <div className="row mt-2 mb-1" key={i} >
                                    <div className="col-12" > 
                                        <span className="small-box" style={{background: item.color }}  ></span>
                                        {item.name} { item.suplyPercentage }% 
                                    </div>
                                </div>
                            );
                        })}
                    </div>
*/