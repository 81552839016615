import React from 'react';

const ImageLoad = ({ uri }) => {
    const [loaded, setLoaded] = React.useState(false);
    const handleOnLoad = () => {
        setLoaded(true);
    }
    return <>
        <img
            src={uri}
            onLoad={handleOnLoad}
            style={loaded ? { display: 'block' } : { display: 'none' }}
        />
        {!loaded &&
            <div className="media-preloader pt-5" > <div className="loader"></div> </div>
        }
    </>;
};

export default ImageLoad;